body {
  font-family: Arial;
  font-size: 0.9rem;
}

/* .mic-off:hover,
.mic-off:focus {
  background-color: #c82333 !important;
  box-shadow: none;
} */

.wrapper {
  background: #f4f6f9;
}

.content-wrapper {
  padding: 15px 0;
}

.rightsidebar {
  padding-right: 0 !important;
}

.card {
  border: 1px solid #ccc;
  box-shadow: none;
  margin-bottom: 1rem !important;

  position: relative;
  display: -ms-flexbox;
  display: flex;
  flex: 1 1 auto;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border-radius: 0.25rem;
}

.text-light {
  color: #aaa !important;
}

video {
  background-color: transparent;
  /* width: 300px; */
  cursor: pointer;
}

/* @media screen and (min-width: 2000px) {
  video {
    height: 150px !important;
  }
}

@media screen and (max-width: 1999px) {
  video {
    height: 130px !important;
  }
} */

.form-group label {
  font-weight: 700;
}

.video-render {
  /* max-height: 150px; */
  /* height: 262px !important;
  background-color: whitesmoke; */
  position: relative;
}

video.largevideo {
  height: auto !important;
  width: 100%;
  border-radius: 18px;
  object-fit: cover;
  /* max-height: 236px; */
}
.cursor {
  cursor: pointer;
}

.audio-level-meter-text {
  float: left;
  padding-bottom: 5px;
  padding-right: 5px;
  line-height: 19px;
}

a {
  cursor: pointer;
}

/* METER CSS */

.pids-wrapper {
  width: 100%;
}

.pid {
  width: 13px;
  height: 13px;
  border-radius: 10px;
  /* display: inline-block; */
  margin: 0px;
  background-color: #c0c7cd80;
  /* border-top: 1px solid lightgrey; */
  /* border-bottom: 1px solid lightgrey; */
  /* display: flex; */
  border: 0;
}

/* .pid:first-child {
  border-left: 1px solid lightgrey;
}

.pid:last-child {
  border-right: 1px solid lightgrey;
} */

.pid.green {
  background-color: #69ce2b;
}

.pid.yellow {
  background-color: yellow;
}

.pid.red {
  background-color: #dc3545;
}

/* APP CSS  */
.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-white {
  background-color: #fff;
}

.navbar-light {
  background-color: #f8f9fa;
}

.main-header {
  border-bottom: 1px solid #dee2e6;
  z-index: 1034;
}

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 0.5rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}

a {
  text-decoration: none;
  background-color: transparent;
}

.main-header .brand-image {
  /* margin-top: -0.5rem; */
  margin-right: 0.2rem;
  height: 33px;
}

.brand-text {
  text-transform: none;
  color: rgba(0, 0, 0, 0.9);
  font-size: 1.2rem;
}

.font-weight-light {
  font-weight: 500 !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.btn-group-sm > .btn,
.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.btn-light {
  color: #1f2d3d;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
  box-shadow: none;
}

.btn {
  display: inline-block;
  font-weight: 400;
  /* color: #212529; */
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  /* background-color: transparent;
  border: 1px solid transparent; */
  /* padding: 0.375rem 0.75rem; */
  font-size: 1rem;
  /* line-height: 1.5; */
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.main-footer {
  background: #fff;
  border-top: 1px solid #dee2e6;
  color: #869099;
  padding: 1rem;
  transition: margin-left 0.3s ease-in-out;
  margin-left: 0;
}

.hint {
  display: none;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 15px;
  background-color: #ffffcc;
  font-weight: normal;
  text-align: left;
  font-size: 0.8rem;
  line-height: 150%;
}

.largeVideoMessage {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 15px;
  background-color: #ffffcc;
  font-weight: normal;
  text-align: left;
  font-size: 0.8rem;
  line-height: 150%;
  animation: msgscrolldown 0.4s ease-in-out forwards;
}
.largeVideoMessage.msgscroll {
  animation: msgscroll 0.4s ease-in-out forwards;
}

.chat-bubble {
  padding: 8px;
  background-color: #f5f5f5;
  border-radius: 6px;
  margin-left: 8px;
}

.bg-success {
  background-color: #28a745 !important;
}

.bg-success,
.bg-success > a {
  color: #fff !important;
}

.opacity-5 {
  opacity: 0.5;
}

.bg-warning,
.bg-warning > a {
  color: #1f2d3d !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

.alert-dismissible {
  padding-right: 3rem;
}

.alert-danger {
  color: white;
  background-color: rgb(238, 100, 100);
  border-color: #a84e58;
  border-width: 2px;
}

.close {
  background-color: red !important;
  color: white !important;
  opacity: 0.6 !important;
  padding: 0.2rem 0.6rem !important;
  font-size: 1.2rem !important;
  border-radius: 2px;
}

.table-striped tbody tr:nth-of-type(even) {
  background-color: #fff;
}

.bg-table-header {
  background-color: #1c3b4e;
  color: white;
}

.btn-info {
  color: #fff;
  background-color: #2198ab;
  border-color: #17a2b8;
  box-shadow: none;
}

.modal-dialog-scrollable {
  height: auto !important;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.animate__animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.animate__flash {
  -webkit-animation-name: flash;
  animation-name: flash;
}

.animate__animated.animate__infinite {
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

.badge {
  line-height: 130%;
}

@-webkit-keyframes flash {
  0%,
  50%,
  to {
    opacity: 1;
  }

  25%,
  75% {
    opacity: 0;
  }
}

@keyframes flash {
  0%,
  50%,
  to {
    opacity: 1;
  }

  25%,
  75% {
    opacity: 0;
  }
}

#chat-form {
  padding: 8px;
  background-color: #3f96f1;
  color: white;
}

.card-dark-outline {
  border-top: 3px solid #343a40;
}

.interface-tr {
  border-top: none !important;
}

.modal-backdrop {
  /* --bs-backdrop-zindex: 1050; */
  /* --bs-backdrop-bg: #000;
  --bs-backdrop-opacity: 0.5; */
  position: fixed;
  top: 0;
  left: 0;
  /* z-index: inherit; */
  width: 100vw;
  height: 100vh;
}

.dropdown-menu.show {
  z-index: 99999;
}

.form-radio {
  margin-left: -1.6rem;
}

.radio-label {
  margin-left: 0.5rem;
  margin-bottom: 0rem;
}

/* Theme Dark.Light */
.App.dark {
  background-color: rgb(0, 30, 60);
  color: white;
  height: 100vh;
}

.App.dark .navbar-white,
.App.dark .card,
.App.dark .wrapper,
.App.dark table,
.App.dark .span,
.App.dark .brand-text,
.App.dark .main-footer {
  background-color: rgb(0, 30, 60) !important;
  color: white !important;
}

.App.dark p,
.App.dark strong,
.App.dark a,
.App.dark td,
.App.dark .text-muted {
  color: white !important;
}

/* .App.dark i.text-muted,
.App.dark .mic-status.fa-solid.fa-microphone-slash,
.App.dark .ear-status.fa-solid.fa-ear,
.App.dark .chime-status.fa-solid.fa-bell-on {
  color: #6c757d !important;
} */

.App.dark div.bg-light.border-bottom,
.App.dark .dropdown-menu,
.App.dark .hint {
  background-color: #3b4280 !important;
}

.App.dark .largeVideoMessage {
  background-color: #3b4280 !important;
}

.App.dark .nav-link.active {
  background-color: #0d6efd;
}

.App.dark #participants-pane tr,
.App.dark .modal-content {
  background-color: #3b4280 !important;
}

.App.dark #participants-pane tr:nth-of-type(odd),
.App.dark .table-striped tbody tr:nth-of-type(even),
.App.dark td.bg-light.small.font-italic {
  background-color: #8b90c0 !important;
}

.App.dark .list-group-item {
  background-color: black;
  color: white;
}

.App.dark .btn-outline-primary {
  color: white !important;
  border-color: white;
}

.App.dark .fs-chat.fw-chat.bg-light.bg-light-c,
.App.dark .chat-bubble .chat-bubble,
.App.dark .chat-bubble .chat-bubble .img-label {
  color: white !important;
  background-color: black !important;
}

.App.dark .bg-light.bg-lighter,
.App.dark textarea:not([disabled])::-webkit-input-placeholder {
  color: white !important;
  background-color: black !important;
}

.slider-handle {
  width: 25px;
  height: 25px;
  background-color: #b7d1df;
  background-image: none;
  border: 6px solid #69ce2b;
  border-radius: 15px !important;
  margin-left: -10px;
}

.tooltip.tooltip-main.top.in {
  opacity: 0.9;
}

.fs-chat {
  font-size: 18px;
}

.fs-chat-t {
  font-size: 16px !important;
}

.fw-chat {
  font-weight: 550;
}

.content-wrapper .alert.alert-dismissible p {
  margin: 0;
}

/* .mic-status {
  position: absolute;

  right: 20px;
  top: 7px
}

.ear-status {
  position: absolute;
  right: 45px;
  top: 7px
}

.chime-status {
  position: absolute;
  right: 65px;
  top: 7px
} */

.mic-status-remote {
  position: absolute;
  bottom: 23%;
  right: 50% !important;
}

.mic-status-video {
  position: absolute;
  bottom: 23%;
  /* right: 47%; */
}

.ear-status-video {
  position: absolute;
  bottom: 23%;
  right: 37%;
}

.icon-position {
  position: absolute;
  top: 0;
  right: 0;
  padding: 4px 4px;
  margin: auto;
  max-width: 40%;
  width: fit-content;
  display: flex;
  justify-content: center;
  background-color: #0d6efd !important;
  color: #6c757d !important;
  border-radius: 15px;
}

.chime-status-video {
  position: absolute;
  bottom: 23%;
  right: 37%;
}

.video-thumb {
  position: relative;
  width: 100% !important;
}

.noImage-thumb {
  max-height: 150px;
}

.Toastify__toast-theme--light {
  background-color: #c1c9cf;
  font-size: 16px;
  color: #1f2d3d;
  font-weight: bold;
}

.bg-disabled {
  background-color: #d6d8d9 !important;
}

.custom-select {
  width: 100%;
  height: calc(2rem + 2px);
  line-height: 1rem;
}

.button-position {
  margin-right: auto;
}

.video-render video {
  height: 100% !important;
  object-fit: cover;
  border-radius: 15px;
}

.table-list {
  max-height: 360px;
  overflow: auto;
}

.videos-card-body {
  position: relative;
  min-height: 116px;
}

.loading-txt {
  font-weight: bold;
  display: inline-block;
  /* font-family: monospace; */
  font-size: 24px;
  /* clip-path: inset(0 1.9ch 0 0); */
  /* animation: load 1.2s steps(4) infinite; */
  display: flex;
  align-items: center;
}

@keyframes load {
  to {
    clip-path: inset(0 -1ch 0 0);
  }
}

.video-controls {
  display: flex;
  justify-content: space-between;
  max-width: -moz-fit-content;
  max-width: fit-content;
  background-color: #0d6efd;
  padding: 3px 7px;
  position: absolute;
  width: 100%;
  top: 2px;
  right: 4px;
  border-radius: 15px;
  /* flex-direction: row-reverse; */
  gap: 10px;
}

.video-controls i,
.icon-position i {
  color: #fff;
}

.App.dark .send-to {
  background-image: url("data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%2732%27 height=%2732%27 id=%27arrow%27%3E%3Cpath d=%27M16.682 19.674c.01-.012.014-.028.024-.04l6.982-7.714c.39-.434.39-1.138 0-1.572-.004-.004-.008-.006-.012-.008a.936.936 0 0 0-.712-.34H8.998a.948.948 0 0 0-.722.352l-.004-.004a1.202 1.202 0 0 0 0 1.572l6.998 7.754a.928.928 0 0 0 1.412 0z%27 fill=%27%23fff%27%3E%3C/path%3E%3C/svg%3E");
  background-position-x: right;
  background-position-y: center;
}

.send-to {
  background: unset;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32' id='arrow'%3E%3Cpath d='M16.682 19.674c.01-.012.014-.028.024-.04l6.982-7.714c.39-.434.39-1.138 0-1.572-.004-.004-.008-.006-.012-.008a.936.936 0 0 0-.712-.34H8.998a.948.948 0 0 0-.722.352l-.004-.004a1.202 1.202 0 0 0 0 1.572l6.998 7.754a.928.928 0 0 0 1.412 0z'%3E%3C/path%3E%3C/svg%3E");
  background-position-x: right;
  background-position-y: center;
  background-size: 19px;
}

#chat-dd {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  gap: 15px;
}

#chat-form {
  padding: 17px 11px;
  background-color: #e6e6e6;
}

#chat-dd .chat-send-to {
  margin: 0;
  color: #000;
  font-weight: 600;
  font-size: 18px;
  line-height: 1;
  .newMessageAlert {
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 3px 8px;
    border-radius: 5px;
  }

  .newMessageAlert p {
    margin: 0;
  }

  .newMessageAlert button {
    color: #fff;
    background: #ffc107;
    border: unset;
    border-radius: 5px;
  }

  .newMessageAlert {
    background: #198754;
  }
}

.App.dark #chat-dd .chat-send-to {
  color: #fff;
}

.App.dark #chat-form {
  background-color: #3b4280;
}

.videos-card-body {
  position: relative;
}

.videos-card-body:has(.loading-txt) {
  min-height: 208px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.spinner {
  margin: 0 auto;
  width: 50px;
  text-align: center;
}

.spinner > div {
  width: 10px;
  height: 10px;
  background-color: #212529;
  border-radius: 100%;
  display: inline-block;
  animation: sk-bouncedelay 1.2s infinite ease-in-out both;
  margin: 0 3px;
}

.App.dark .spinner > div {
  background-color: #ffffff;
}

.spinner .bounce1 {
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  animation-delay: -0.2s;
}

.spinner .bounce3 {
  animation-delay: -0.1s;
}

@-webkit-keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    transform: scale(0);
  }

  40% {
    transform: scale(1);
  }
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    transform: scale(0);
  }

  40% {
    transform: scale(1);
  }
}

@media screen and (max-width: 992px) {
  .loading-txt {
    font-size: 18px;
  }

  .spinner {
    width: 38px;
  }

  .spinner > div {
    width: 6px;
    height: 6px;
  }

  .rightsidebar {
    padding-right: 12px !important;
  }
}

@media screen and (max-width: 375px) {
  .loading-txt {
    font-size: 16px;
  }

  .spinner {
    width: 32px;
  }

  .spinner > div {
    width: 4px;
    height: 4px;
  }
}

.userName {
  width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center !important;
  display: block;
  margin: 5px;
}
.content-video-sec {
  display: flex;
  align-items: center;
  justify-content: center;
}
/* END APP CSS */

/*  new css 22 march */

/* .mic-off {
  background-color: #c82333  !important;
} */
.mic-on {
  background-color: #69ce2b !important;
}
.unmute {
  background-color: #234464 !important;
}
.mute {
  background-color: #c82333 !important;
}

.audio-badge {
  padding: 5px 10px;
  border-radius: 20px;
  background-color: #198754;
  color: #fff;
  display: block;
  max-width: fit-content;
  margin-bottom: 15px;
}
.audio-badge p {
  margin: 0;
}
.pids-wrapper {
  display: flex;
  gap: 4px;
}

.pid.active {
  background-color: #69ce2b;
}
button#mute-boothmates-btn {
  background-color: #234464;
  border: 0;
}
.slider-selection {
  background: #69ce2b;
}
.slider.slider-horizontal .slider-track {
  height: 3px;
  width: 100%;
  margin-top: -2px;
}
button#mute-boothmates-btn {
  background-color: #234464;
  border: 0;
}
.out-audio {
  background-color: #ffc107;
}
.App.dark .out-audio p {
  color: #000 !important;
}
.audio-manage-btn {
  height: 60px !important;
  border-radius: 10px;
  font-size: 16px;
  padding: 16px 10px;
  background-color: #234464;
  border: 0;
}
/* .audio-manage-btn:hover {
  background-color: #3d6083 !important;
} */
.mute {
  background-color: #dc3545;
}
.audio-bar {
  min-height: 50px;
  display: flex;
  align-items: center;
}
.audio-manage-btn.btn-primary {
  background: #0069d9 !important;
}

.mic-onn {
  background: #dc3545;
  color: #fff;
}
.mic-off {
  background-color: #78ad2e !important;
  color: #fff;
}
#cough-btn {
  height: 40px !important;
  padding: 5px 10px;
}

/* mar 26 */

.VideoMessage-outer {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

@keyframes msgscroll {
  0% {
    transform: translateY(0%);
  }

  25% {
    transform: translateY(-10px);
    opacity: 0.5;
  }

  100% {
    transform: translateY(-50px);
    opacity: 0.2;
  }
}
@keyframes msgscrolldown {
  0% {
    transform: translateY(-50px);
    opacity: 0;
  }

  25% {
    transform: translateY(-25px);
    opacity: 0.5;
  }

  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}
.text-center.main-video-wrapper {
  /* max-height: 236px; */
  /* max-width: 412px; */
}
.text-center.main-video-wrapper svg {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.bottomToUpChat .list-group {
  display: flex;
  flex-direction: column-reverse;
  min-height: 65vh;
}
.bottomToUpChat {
  display: flex;
  flex-direction: column-reverse;
}
.chat-btn {
  background: #0d6efd;
  color: #fff;
  display: flex;
  gap: 10px;
  white-space: normal;
  justify-content: center;
  align-items: center;
  padding: 3px 14px;
  font-weight: 550;
}
.chat-btn:hover {
  background: #0d6efd !important;
}
.chat-btn svg {
  fill: currentColor;
  width: 12px;
}
.auto-video-height {
  height: 55vh;
  overflow: auto;
}
.video-tab-height {
  min-height: 300px;
}
.video-row {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}
.video-col-bx {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  border-radius: 14px;
}
.video-row .video-render:has(.noImage-thumb) {
  display: flex;
  flex-direction: column;
  background: #3b4280;
  height: 100%;
  justify-content: center;
  border-radius: 15px;
}
.left-btn-bx {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  border: 1px solid;
  padding: 5px;
  border-radius: 5px;
}
.left-btn-bx-inner {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
}
.left-btn-bx label {
  margin: 0;
}
.newMessageAlert {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 3px 8px;
  border-radius: 5px;
}

.newMessageAlert p {
  margin: 0;
}

.newMessageAlert button {
  color: #fff;
  background: #ffc107;
  border: unset;
  border-radius: 5px;
}

.newMessageAlert {
  background: #198754;
}
/* 30 April Css */
.interpreter-list-bx {
  justify-content: center;
  gap: 13px;
  align-items: center;
}
.interpreter-list-bx .audio-manage-btn {
  margin-bottom: 0 !important;
  height: 35px !important;
  padding: 6px 13px !important;
}
.interpreter-list-bx .chime-volume {
  position: static;
}
#interpreters-pane {
  height: 51vh;
}

.signal_icon {
  /* position: absolute; */
  /* left: 4px; */
  background: #1ec26a;
  border-radius: 8px;
  padding: 4px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 26px;
  height: 26px;
  cursor: pointer;
}
.localStats {
  background: #141414;
  padding: 9px 10px;
  position: absolute;
  top: 100%;
  min-width: 190px;
  border-radius: 10px;
}
.mainLocalStats {
  position: absolute;
  top: 3px;
  left: 5px;
}
.bitrate-stats {
  display: flex;
  align-items: center;
}
.localStatsList label {
  margin: 0;
  text-align: left;
}
.localStats ul {
  padding: 0;
  font-size: 14px;
  text-align: left;
  margin: 0;
}
.localStats svg {
  width: 17px;
  height: 11px;
}
.localStatsList li {
  display: grid;
  grid-template-columns: 1fr auto;
}
.filterDropDown {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
  max-width: 392px;
}

.filterDropDown label {
  margin: 0;
  font-size: 18px;
}

.video-col {
  /* min-width: 250px; */
  width: 100%;
  /* max-width: 250px; */
  padding: 8px;
  overflow: hidden;
  border-radius: 14px;
}
.video-row {
  width: 100%;
  display: flex;
  /* overflow: auto; */
  scroll-behavior: smooth;
  cursor: grabbing;
  padding: 5px 5px;
  gap: 8px;
  padding-bottom: 20px;
}
.video-row::-webkit-scrollbar {
  height: 6px;
  background-color: white;
  border-radius: 3px;
}
.video-row::-webkit-scrollbar-thumb {
  background-color: #999;
  border-radius: 3px;
}
.video-row::-webkit-scrollbar-button {
  display: none;
}
.video-render video {
  min-height: 150px;
}
.videos-card-body:has(.loading-txt) .video-row {
  display: none;
}
.App.dark .video-col-bx {
  outline-color: #fff !important;
}

@media screen and (max-width: 1440px) {
  .video-row {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (max-width: 992px) {
  .video-row {
    grid-template-columns: auto;
  }
}

/* .swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
} */
.dark .swiper-pagination-bullet {
  background: #ffc107;
  margin-top: 6px;
}